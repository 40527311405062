import React from 'react';
import RiskFactorGraph from '../../components/risk-factor-graph';
import RiskGraph from '../../components/risk-line-graph';
import { getPercentileText2, findBucket } from '../../utils/results';

// UER.percentile is required or the page does not load.

const ChartConfig = require('../../../json/ostp-config.json');

export const Mars_HOsteoResults = (props) => {
    const { result, userName, nameEndsWithS } = props;
    let testComponent,  KOsteoIndex, HOsteoIndex;
    const setupData = ChartConfig.setupData;
    const { Mars_KOsteo_Risk, adjusted_Mars_KOsteo_Risk, avg_Mars_KOsteo_Result,avg_Adjusted_Mars_KOsteo_Result, Mars_HOsteo_Risk, adjusted_Mars_HOsteo_Risk, avg_Mars_HOsteo_Result,avg_Adjusted_Mars_HOsteo_Result,percentile_to_SD } = setupData;
    let adjusted = result['adjusted_result'];
    let percentile, raw_result, SD, OR, disease_risk;
    let  KOsteoRiskPercent, KOsteoRiskPercent2, HOsteoRiskPercent, HOsteoRiskPercent2;

    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
        //turn on when HOsteo has adjusted results
        adjusted = result['adjusted_result'];
        HOsteoIndex = findBucket(adjusted, avg_Adjusted_Mars_HOsteo_Result);
        HOsteoRiskPercent2 = adjusted_Mars_HOsteo_Risk[HOsteoIndex]; 
    } else {
        raw_result = result['value'];
        KOsteoIndex = findBucket(raw_result, avg_Mars_KOsteo_Result);   
        KOsteoRiskPercent = Mars_KOsteo_Risk[KOsteoIndex];  
        HOsteoIndex = findBucket(raw_result, avg_Mars_HOsteo_Result);   
        HOsteoRiskPercent = Mars_HOsteo_Risk[HOsteoIndex];  
    }
    SD = percentile_to_SD[percentile-1]
    OR = 1.38  //fill in manually for each disease
    disease_risk = Math.pow(OR, SD)

    if (result['value'] !== null) {

        testComponent =
            <div>
                {adjusted ? (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for hip osteoarthritis. Your score is comprised of both the genetic and clinical risk factor results.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-adjusted-Mars_HOsteo-histogram' />
                            
                            <br></br>
                            <p> You are in the <b>{getPercentileText2(percentile)} </b> and your risk for hip osteoarthritis is <b>{disease_risk.toPrecision(2)}</b> fold compared to the average. Your height, sex, age and weight have been factored in to your score to better predict your risk for hip osteoarthritis.</p>

                        </div>
                    </>
                ) : (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for hip osteoarthritis.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-Mars_HOsteo-histogram' />
                            <br></br>
                            <p>You are in the <b>{getPercentileText2(percentile)}</b> and your risk for hip osteoarthritis is <b>{disease_risk.toPrecision(2)}</b> fold compared to the average. </p>
                        </div>
                    </>
                )}
                <div>
                </div>
                <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
            <p>This study developed a polygenic risk score for hip osteoarthritis that includes 1,052,273 variants based on 306,418 individuals (15,704 cases).</p>
            <p>The study was limited to individuals of European ancestry. It is not clear how well the genetics perform for other ancestries.</p>
            </div>                
            </div>

        return (
            <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Hip Osteoarthritis</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
            </div>

                {testComponent}

            </div>
        );
    };
};


export default Mars_HOsteoResults;



/////////
// import React from 'react';
// import BetaHistogram from '../../components/beta-histogram';
// import TagPercentile from '../../components/tag-percentile';
// import { useSelector } from 'react-redux';

// export const Mars_HOsteoResults = ({ userName, nameEndsWithS, uploadID, width }) => {
//     // this page contains the results of multiple tests
//     const { crm, uploads } = useSelector((state) => ({
//         crm: state.crm,
//         uploads: state.results.uploads,
//     }));

//     const getGraph = (tag) => {
//         const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});

//         const crmKey = `results-${tag}-histogram`;
//         return <BetaHistogram crm={crm} result={tagResult} crmKey={crmKey} width={width} />;
//     };

//     const getPercentile = (tag) => {
//         const tagResult = (((uploads[uploadID] || {})['results'] || {})[tag] || {});
//         return <TagPercentile result={tagResult} tag={tag} usesUKData={false} />;
//     };
//     return (
//         <div>
//             <div className='title-margins'>
//                 <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Hip Osteoarthritis</h2>
//                 <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
//             </div>
//             <div className='title-break'>
//                 <span>Your Results</span>
//             </div>
//             <div className="card">
//                 <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have higher risk for hip osteoarthritis. (Units for Beta are arbitrary.)</p>

//                 {getGraph("Mars_HOsteo")}

//                 <p>{getPercentile("Mars_HOsteo")} A score in the 84th percentile has about a 1.38 fold increased risk. A score in the 97th percentile has about a 2.76 fold increased risk. </p>
//             </div>
//             <div className='title-break'>
//                 <span>Comments</span>
//             </div>
//             <div className="card">
//                 <p>This study developed a polygenic risk score for hip osteoarthritis that includes 1,052,273 variants based on 306,418 individuals (15,704 cases).</p>
//                 <p>The study was limited to individuals of European ancestry. It is not clear how well the genetics perform for other ancestries.</p>
//             </div>
//         </div>
//     );
// };

// export default Mars_HOsteoResults;
