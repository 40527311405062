import React from 'react';
import ReactTooltip from 'react-tooltip';
import { loadCRMThunk } from '../actions';
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import { hexMixer } from '../utils/colors';
import { connect } from 'react-redux';

// Makes a histogram and line chart for disease. Series data to draw the graphs are from ostp-config.json.

const chartConfig = require('../../json/ostp-config.json');

const percentFormat = (val) => {
    return (val > 1 ? '+' : '') + (val).toString() + '%';
};

const foldFormat = (val) => {
    return (val > 1 ? '+' : '') + (val).toString() + ' fold';
};

//titles for the x axis in the graph.
const betaTitles = {
    'results-Yu_CKD-histogram': 'CKD Genetic Score',
    'results-adjusted-Yu_CKD-histogram': 'CKD Genetic Score',
    'results-Mars_arth-histogram': 'Arthritis Genetic Score',
    'results-adjusted-Mars_arth-histogram': 'Arthritis Genetic Score',
    'results-Mars_KOsteo-histogram': 'Knee Osteoarthritis Genetic Score',
    'results-adjusted-Mars_KOsteo-histogram': 'Knee Osteoarthritis Genetic Score',
    'results-Mars_HOsteo-histogram': 'Hip Osteoarthritis Genetic Score',
    'results-adjusted-Mars_HOsteo-histogram': 'Hip Osteoarthritis Genetic Score',
    'results-Mars_ColCan-histogram': 'Colorecteal Cancer Genetic Score',
    'results-adjusted-Mars_ColCan-histogram': 'Colorectal Cancer Genetic Score',
};

// Titles for the chart on top.
const chartTitles = {
    'results-Yu_CKD-histogram': 'CKD Risk',
    'results-adjusted-Yu_CKD-histogram': 'CKD Risk (adjusted with clinical risk factors)',
    'results-Mars_arth-histogram': 'Arthritis Risk',
    'results-adjusted-Mars_arth-histogram': 'Arthritis Risk (adjusted with clinical risk factors)',
    'results-Mars_KOsteo-histogram': 'Knee Osteoarthritis Risk',
    'results-adjusted-Mars_KOsteo-histogram': 'Knee Osteoarthritis Risk (adjusted with clinical risk factors)',
    'results-Mars_HOsteo-histogram': 'Hip Osteoarthritis Risk',
    'results-adjusted-Mars_HOsteo-histogram': 'Hip Osteoarthritis Risk (adjusted with clinical risk factors)',
    'results-Mars_ColCan-histogram': 'Colorectal Cancer Risk',
    'results-adjusted-Mars_ColCan-histogram': 'Colorectal Cancer Risk (adjusted with clinical risk factors)',
};

// Series name in ostp-config.json for the fold risk for each bin.
const chartRisks= {
    'results-Yu_CKD-histogram': 'CKDRisk',
    'results-adjusted-Yu_CKD-histogram': 'adjustedCKDRisk',
    'results-Mars_arth-histogram': 'Mars_arth_Risk',
    'results-adjusted-Mars_arth-histogram': 'adjusted_Mars_arth_Risk',
    'results-Mars_KOsteo-histogram': 'Mars_KOsteo_Risk',
    'results-adjusted-Mars_KOsteo-histogram': 'adjusted_Mars_KOsteo_Risk',
    'results-Mars_HOsteo-histogram': 'Mars_HOsteo_Risk',
    'results-adjusted-Mars_HOsteo-histogram': 'adjusted_Mars_HOsteo_Risk',
    'results-Mars_ColCan-histogram': 'Mars_ColCan_Risk',
    'results-adjusted-Mars_ColCan-histogram': 'adjusted_Mars_ColCan_Risk',
};

// lower bound of the percentile for each bin in the histogram.
const chartPercentiles= {
    'results-Yu_CKD-histogram': 'CKDPercentiles',
    'results-adjusted-Yu_CKD-histogram': 'adjustedCKDPercentiles',
    'results-Mars_arth-histogram': 'Mars_arth_Percentiles',
    'results-adjusted-Mars_arth-histogram': 'adjusted_Mars_arth_Percentiles',
    'results-Mars_KOsteo-histogram': 'Mars_KOsteo_Percentiles',
    'results-adjusted-Mars_KOsteo-histogram': 'adjusted_Mars_KOsteo_Percentiles',
    'results-Mars_HOsteo-histogram': 'Mars_HOsteo_Percentiles',
    'results-adjusted-Mars_HOsteo-histogram': 'adjusted_Mars_HOsteo_Percentiles',
    'results-Mars_ColCan-histogram': 'Mars_ColCan_Percentiles',
    'results-adjusted-Mars_ColCan-histogram': 'adjusted_Mars_ColCan_Percentiles',
};

// average result for each of the columns.
const chartResults= {
    'results-Yu_CKD-histogram': 'avgCKDResult',
    'results-adjusted-Yu_CKD-histogram': 'avgAdjustedCKDResult',
    'results-Mars_arth-histogram': 'avg_Mars_arth_Result',
    'results-adjusted-Mars_arth-histogram': 'avg_Adjusted_Mars_arth_Result',
    'results-Mars_KOsteo-histogram': 'avg_Mars_KOsteo_Result',
    'results-adjusted-Mars_KOsteo-histogram': 'avg_Adjusted_Mars_KOsteo_Result',
    'results-Mars_HOsteo-histogram': 'avg_Mars_HOsteo_Result',
    'results-adjusted-Mars_HOsteo-histogram': 'avg_Adjusted_Mars_HOsteo_Result',
    'results-Mars_ColCan-histogram': 'avg_Mars_ColCan_Result',
    'results-adjusted-Mars_ColCan-histogram': 'avg_Adjusted_Mars_ColCan_Result',
};

// bin size for each of the columns.
const chartBins= {
    'results-Yu_CKD-histogram': 'binCKDSize',
    'results-adjusted-Yu_CKD-histogram': 'binAdjustedCKDSize',
    'results-Mars_arth-histogram': 'binArthSize',
    'results-adjusted-Mars_arth-histogram': 'binAdjustedArthSize',
    'results-Mars_KOsteo-histogram': 'binKOsteoSize',
    'results-adjusted-Mars_KOsteo-histogram': 'binAdjustedKOsteoSize',
    'results-Mars_HOsteo-histogram': 'binHOsteoSize',
    'results-adjusted-Mars_HOsteo-histogram': 'binAdjustedHOsteoSize',
    'results-Mars_ColCan-histogram': 'binColCanSize',
    'results-adjusted-Mars_ColCan-histogram': 'binAdjustedColCanSize',
};

// Maximum fold risk for the left y-axis.
const chartLeftYAxes= {
    'results-Yu_CKD-histogram': 15,
    'results-adjusted-Yu_CKD-histogram': 32,
    'results-Mars_arth-histogram': 4,
    'results-adjusted-Mars_arth-histogram': 4,
    'results-Mars_KOsteo-histogram': 4,
    'results-adjusted-Mars_KOsteo-histogram': 4,
    'results-Mars_HOsteo-histogram': 4,
    'results-adjusted-Mars_HOsteo-histogram': 4,
    'results-Mars_ColCan-histogram': 3,
    'results-adjusted-Mars_ColCan-histogram': 4,
};
// Maximum percent population for the right y-axis
const chartRightYAxes= {
    'results-Yu_CKD-histogram': 25,
    'results-adjusted-Yu_CKD-histogram': 25,
    'results-Mars_arth-histogram': 20,
    'results-adjusted-Mars_arth-histogram': 20,
    'results-Mars_KOsteo-histogram': 20,
    'results-adjusted-Mars_KOsteo-histogram': 20,
    'results-Mars_HOsteo-histogram': 20,
    'results-adjusted-Mars_HOsteo-histogram': 20,
    'results-Mars_ColCan-histogram': 25,
    'results-adjusted-Mars_ColCan-histogram': 20,
};

class RiskLineGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            chartData: false, 
            betaTitle: (this.props.crmKey in betaTitles) ? betaTitles[this.props.crmKey] : 'Beta', 
            chartTitle: chartTitles[this.props.crmKey],
            chartRiskKey: chartRisks[this.props.crmKey], 
            chartPercentilesKey: chartPercentiles[this.props.crmKey],
            chartResultsKey: chartResults[this.props.crmKey],
            chartBinsKey: chartBins[this.props.crmKey],
            chartLeftYAxesKey: chartLeftYAxes[this.props.crmKey],
            chartRightYAxesKey: chartRightYAxes[this.props.crmKey],
        };
    };

    // risk is for disease risk.
    componentDidMount() {
        this.props.loadCRM(this.props.crmKey);
        if (this.props.graphType === 'risk') {
            this.prepareRiskGraph();
        }
    };

    resetGraphTooltips() {
        // Set BMD Graph Title
        Array.from(document.querySelectorAll('.osteo-graph .apexcharts-title-text')).map(ele => {
            ele.setAttribute('data-tip', 'Shows your risk of disease compared to the average; e.g.8 fold means increased risk and 0.18 means decreased risk of disease.');
            return ele;
        });

        // Set x-axis
        Array.from(document.getElementsByClassName('apexcharts-xaxis-title-text')).map(ele => {
            ele.setAttribute('data-tip', 'High scores indicate an increased risk for disease. Low PRS scores correlate with decreased risk.');
            ele.setAttribute('data-place', 'bottom');
            return ele;
        });

        // Set y-axis
        Array.from(document.getElementsByClassName('apexcharts-yaxis-title-text')).map(ele => {
            ele.setAttribute('data-tip', 'Grey bars show the % population contained in each bin.');
            ele.setAttribute('data-place', 'right');
            return ele;
        });

        ReactTooltip.rebuild();
    };

    // for Risk graph. 
    prepareRiskGraph() {
        const { graphType, percentile, width, result } = this.props;
        const chartData = JSON.parse(JSON.stringify(chartConfig));
        const { setupData } = chartData;
        const chartRiskKey = chartRisks[this.props.crmKey];
        const chartRisk = setupData[chartRiskKey];
        const chartPercentileKey = chartPercentiles[this.props.crmKey];
        const chartPercentile = setupData[chartPercentileKey];
        const chartResultsKey = chartResults[this.props.crmKey];
        const chartResult = setupData[chartResultsKey];
        const chartBinsKey = chartBins[this.props.crmKey];
        const chartBin = setupData[chartBinsKey];
        const chartLeftYAxesKey = chartLeftYAxes[this.props.crmKey];
        const chartRightYAxesKey = chartRightYAxes[this.props.crmKey];
        const binIndex = this.findBinIndex(percentile, chartPercentile);
        const riskPercent = (chartRisk[binIndex] - Math.min(...chartRisk)) * 100.0 /
            (Math.max(...chartRisk) - Math.min(...chartRisk));
        const userRiskColor = hexMixer(
            chartConfig.design.colors[0],
            chartConfig.design.colors[1],
            riskPercent
        );
       
        console.log('binIndex :', binIndex)
        console.log(`percentile = ${percentile}`);
 
        // Setup x-axis
        chartData.options.labels = chartResult; //average result for each of the columns
        chartData.options.xaxis.title = {
            text: this.state.betaTitle,
            offsetY: width < 768 ? -22 : -15,
            style: {
                fontSize: "16px",
                cssClass: "apexcharts-axis-titles"
            }
        };

        // Setup y-axes 
        chartData.series[0].data = chartRisk; 
        chartData.series[1].data = chartBin;
        chartData.options.yaxis[0].labels.formatter = (val) => foldFormat(val);
        chartData.options.yaxis[0].max = chartLeftYAxesKey;
        chartData.options.yaxis[0].min = 0;
        chartData.options.yaxis[1].max = chartRightYAxesKey;
        chartData.options.yaxis[1].min = 0;

        // Create (x,y) point on risk line
        chartData.options.annotations.points[0].x = chartResult[binIndex];
        chartData.options.annotations.points[0].y = chartRisk[binIndex];
        chartData.options.annotations.points[0].marker.strokeColor = userRiskColor;

        // Create horizontal line for user risk
        chartData.options.annotations.yaxis[1].y = chartRisk[binIndex];

        chartData.options.annotations.yaxis[1].borderColor = userRiskColor;
        chartData.options.annotations.yaxis[1].label.borderColor = userRiskColor;
        chartData.options.annotations.yaxis[1].label.style.background = userRiskColor;
        chartData.options.annotations.yaxis[1].label.text = " (you)";

        // Setup styling
        chartData.options.title.text = this.state.chartTitle;
        chartData.options.colors = [chartConfig.design.colors[1], "transparent"];
        chartData.options.fill.gradient.gradientToColors = [chartConfig.design.colors[0]];

        // Setup tooltip
        // determines position for mobile or desktop version
        width < 768 ? (
            chartData.options.tooltip.fixed = { enabled: true, position: 'topRight', offsetX: 10, offsetY: -42 }
        ) : (chartData.options.tooltip.fixed = { enabled: false });

        // make tooltip
        this.generateCustomTooltips('risk', chartData);

        this.setState({ chartData });
    };

    // function adds tooltips to the graph
    generateCustomTooltips(graphType, chartData) {
        chartData.options.tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
            const percentile = series[1].reduce((agg, v, i) => (i < dataPointIndex) ? agg + v : agg, 0.00);
            const riskColor = hexMixer(
                chartConfig.design.colors[1],
                chartConfig.design.colors[0],
                percentile
            );

            //YLabel determines the words used in the second row of the tool tip.
            //graphType determines how the numbers are formatted in the second row of the tool tip: plain, percent or fold.
            const getYLabel = (graphType) => {
                let yLabel;

                if (graphType === 'risk') {
                    yLabel = 'Risk';
                } else {
                    yLabel = 'Risk';
                }
                
                return yLabel;
            }

            const yLabel = getYLabel(graphType)

            const getScoreRange = (graphType, dataPointIndex, series) => {

                if (graphType === 'risk' && dataPointIndex === 0) {
                    return '<' + foldFormat(series[0][dataPointIndex]);
                }
                if (graphType === 'risk' && dataPointIndex === w.config.labels.length - 1) {
                    return '>' + foldFormat(series[0][dataPointIndex]);
                }
                if (graphType === 'risk') {
                    return foldFormat(series[0][dataPointIndex]) + ' to ' + foldFormat(series[0][dataPointIndex + 1]);
                }
            }

            let geneticScoreRange, scoreRange;
            if (dataPointIndex === 0) {  //dataPointIndex is the first in the list
                geneticScoreRange = '<' + w.config.labels[dataPointIndex + 1]; // top row in tool tip has < lowest value
                scoreRange = getScoreRange(graphType, dataPointIndex, series);
            } else if (dataPointIndex === w.config.labels.length - 1) { //dataPointIndex is the last value in the list
                geneticScoreRange = '>' + w.config.labels[dataPointIndex];
                scoreRange = getScoreRange(graphType, dataPointIndex, series);
            } else { //dataPointIndex is in the middle somewhere
                geneticScoreRange = w.config.labels[dataPointIndex] + ' to ' + w.config.labels[dataPointIndex + 1];
                scoreRange = getScoreRange(graphType, dataPointIndex, series);
            };

    // fill in geneticScoreRange/scoreRange/Percentile Range in each bin in the histogram
            return '<div class="bog-graph-tooltip">' +
                '<div class="tooltip-header" style="background-color:' + riskColor + ';">Genetic Score Range: ' + geneticScoreRange + '</div>' +
                `<div>${yLabel} Range: ` + scoreRange + '</div>' +
                '<div>Percentile Range: ' + percentile.toFixed(1) + ' to ' + (percentile + series[1][dataPointIndex]).toFixed(1) + '%</div>' +
                '</div>';
        };
    };

    // Finds the index of the bin containing the percentile. 
    findBinIndex = (percentile, percentilesArray) => {
        const numericPercentile = parseFloat(percentile);

        // Check if the percentile is a valid number
        if (isNaN(numericPercentile)) {
            console.error('Invalid percentile:', percentile);
            return -1; // Return an indicator for an error
        }

        // Convert each value in {tag}}Percentiles to a number for comparison
        const numericPercentiles = percentilesArray.map(value => parseFloat(value));

        // Find the index of the bin containing the percentile
        for (let i = 0; i < numericPercentiles.length - 1; i++) {
            const lowerBound = numericPercentiles[i];
            const upperBound = numericPercentiles[i + 1];

            if (numericPercentile >= lowerBound && numericPercentile < upperBound) {
                return i; // Return the index of the bin
            }
        }

        // If the percentile is greater than or equal to the last bin's upper bound
        if (numericPercentile >= numericPercentiles[numericPercentiles.length - 1]) {
            return numericPercentiles.length - 1; // Return the index of the last bin
        }

        return -1; // Return an indicator for an error (percentile not found in any bin)
    };

    componentDidUpdate(prevProps) {
        if (this.props.binIndex !== prevProps.binIndex) {
            if (this.props.graphType === 'risk') this.prepareRiskGraph();
        }
        this.resetGraphTooltips();
    }
    render() {
        let { chartData = {} } = this.state;
        const { graphType } = this.props;

        return (
            <div className={classNames("graph-container bmd-graph",
                { "osteo-graph": graphType === 'risk' }
            )}>
                {!chartData ? null : (
                    <div>
                        <Chart options={chartData.options} series={chartData.series} type="line" height="350" />
                        <ReactTooltip place="top" className="tooltip-axgen" effect="solid" />
                    </div>
                )}

            </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    loadCRM: (crmKey) => dispatch(loadCRMThunk(crmKey))
  });
  export default connect(null, mapDispatchToProps)(RiskLineGraph);
// export default ;
