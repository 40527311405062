import React from "react";
import BetaHistogram from "../../components/beta-histogram";
import TagPercentile from "../../components/tag-percentile";
import RiskFactorGraph from "../../components/risk-factor-graph";
import { getPercentileText2 } from "../../utils/results";
import { useSelector } from "react-redux";

export const Xu_cbc_mcv = ({ uploadID, userName, nameEndsWithS }) => {
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const tag = "Xu_mcv";
  const tagResult = ((uploads[uploadID] || {}).results || {})[tag] || {};
  const percentile = tagResult.percentile;
  const adjusted = tagResult.adjusted_result;
  const scoreValue = tagResult.value;
  // If no score value, do not render the component
  if (scoreValue === null) return null;

  return (
    <div>
      <div className="title-margins">
        <h2>
          {userName ? `${userName}'${nameEndsWithS ? "" : "s"}` : ""} Results
          for Mean Corpuscular Volume (MCV){" "}
        </h2>
        <h5>
          <a href="https://pubmed.ncbi.nlm.nih.gov/35072137/">
            Xu et al., 2022
          </a>
        </h5>
      </div>

      <div>
        <div className="title-break">
          <span>Your Results</span>
        </div>
        <div className="card">
          <p>
            To use the graph, find the red line that shows your genetic score.
            The blue histogram shows the number of people and their genetic
            scores. The bin containing your score tells how many other people
            have a score like yours. If your score is on the{" "}
            <strong>right side of the histogram</strong>, you will tend to have
            a higher MCV.
          </p>
          <div>
            {adjusted ? (
              <>
                <BetaHistogram
                  crm={crm}
                  result={tagResult}
                  crmKey="results-adjusted_Xu_mcv-histogram"
                />
                <p>
                  Your adjusted PRS predicts your MCV to be{" "}
                  <b>{Number(adjusted).toPrecision(4)} fL</b>. You are in the{" "}
                  <b>{getPercentileText2(percentile)} </b>. Your height, weight,
                  age, sex, smoking status, and alcohol consumption have been
                  factored into your score to better predict your MCV.
                </p>
              </>
            ) : (
              <>
                <BetaHistogram
                  crm={crm}
                  result={tagResult}
                  crmKey="results-Xu_mcv-histogram"
                />
                <TagPercentile result={tagResult} tag="Xu_mcv" />
              </>
            )}
          </div>
        </div>

        <div className="title-break">
          <span>Comments</span>
        </div>
        <div className="card">
        <p>
            This study developed a polygenic risk score for MCV that
            includes 25,001 total variants (PGS000101) using elastic net.
          </p>
          <p>R2 = 0.2203 for the platelet count PRS with no risk factors.      </p>
          <p>R2 = 0.3242 for adjusted risk PRS .</p>
          <p>Normal range: 80 - 100 fL. Stuart has a measured value of 93 fL.</p>
        </div>

        {adjusted && (
          <div>
            <div className="title-break">
              <span>Impact of Genetics and Clinical Risk Factors on MCV</span>
            </div>
            <div className="card">
              <RiskFactorGraph
                graphMax={0.35}
                data={[0.2203, 0.06978, 0.3242]}
              />
              <p>
                The graph shows how MCV is affected by genetics, risk
                factors (height, weight, age, sex, smoking, alcohol), or both
                combined. The amount of risk is measured by "variance
                explained", where 1.00 means that the test predicts MCV exactly
                and 0.00 means that there is no information. For MCV, genetics
                has a larger effect than risk factors, but combining them
                together has the most effect; overall, the combined score
                explains 32% of the variance.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Xu_cbc_mcv;
