import React from 'react';

class Xu_cbc_Intro extends React.Component {
  render() {
    return (
      <div>
        <div className="title-margins">
          <h2>Complete Blood Count</h2>
        </div>

        <div className="table-responsive">
        <table className="gene-table w-100 col-sm-12 table-bordered table-condensed cf">
          <thead>
            <tr>
              <th>Test</th>
              <th>R2</th>
              <th>Genetic Prediction</th>
              <th>Observed Result for Stuart</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>WBC</td>
              <td>0.206</td>
              <td>6.412 K/uL</td>
              <td>6.4 K/uL</td>
            </tr>
            <tr>
              <td>Hematocrit</td>
              <td>?</td>
              <td>99 percentile</td>
              <td>49.7%</td>
            </tr>
            <tr>
              <td>Hemoglobin</td>
              <td>0.495</td>
              <td>16.26 g/dL</td>
              <td>16.7 g/dL</td>
            </tr>
            <tr>
              <td>MCH</td>
              <td>0.2805</td>
              <td>31.82 pg</td>
              <td>31 pg</td>
            </tr>
            <tr>
              <td>MCHC</td>
              <td>0.0642</td>
              <td>34.55 g/dL</td>
              <td>34.2 g/dL</td>
            </tr>  
            <tr>
              <td>MCV</td>
              <td>0.3242</td>
              <td>91.20 fL</td>
              <td>93 fL</td>
            </tr>           
            <tr>
              <td>RBC</td>
              <td>0.4532</td>
              <td>4.894 M/uL</td>
              <td>4.88 M/uL</td>
            </tr>
            <tr>
              <td>Platelet</td>
              <td>0.339</td>
              <td>306.5 K/uL</td>
              <td>228 K/uL</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}

export default Xu_cbc_Intro;
