import React from 'react';
import RiskFactorGraph from '../../components/risk-factor-graph';
import RiskGraph from '../../components/risk-line-graph';
import { getPercentileText2, findBucket } from '../../utils/results';

// UER.percentile is required or the page does not load.

const ChartConfig = require('../../../json/ostp-config.json');

export const Mars_KOsteoResults = (props) => {
    const { result, userName, nameEndsWithS } = props;
    let testComponent,  KOsteoIndex;
    const setupData = ChartConfig.setupData;
    const { Mars_KOsteo_Risk, adjusted_Mars_KOsteo_Risk, avg_Mars_KOsteo_Result,avg_Adjusted_Mars_KOsteo_Result, percentile_to_SD } = setupData;
    let adjusted = result['adjusted_result'];
    let percentile, raw_result, SD, OR, disease_risk;
    let  KOsteoRiskPercent, KOsteoRiskPercent2;

    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
        adjusted = result['adjusted_result'];
        KOsteoIndex = findBucket(adjusted, avg_Adjusted_Mars_KOsteo_Result);
        KOsteoRiskPercent2 = adjusted_Mars_KOsteo_Risk[KOsteoIndex]; 
    } else {
        raw_result = result['value'];
        KOsteoIndex = findBucket(raw_result, avg_Mars_KOsteo_Result);   
        KOsteoRiskPercent = Mars_KOsteo_Risk[KOsteoIndex];  
    }
    SD = percentile_to_SD[percentile-1]
    OR = 1.60  //fill in manually for each disease
    disease_risk = Math.pow(OR, SD)
    console.log('disease risk =', disease_risk)
    console.log('KOsteoIndex =', KOsteoIndex)
    console.log(`KOsteoRiskPercent2 = ${KOsteoRiskPercent2}`);
    console.log(`raw_result = ${raw_result}`);

    if (result['value'] !== null) {

        testComponent =
            <div>
                {adjusted ? (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for knee osteoarthritis. Your score is comprised of both the genetic and clinical risk factor results.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-adjusted-Mars_KOsteo-histogram' />
                            
                            <br></br>
                            <p> You are in the <b>{getPercentileText2(percentile)} </b> and your risk for knee osteoarthritis is <b>{disease_risk.toPrecision(2)}</b> fold compared to the average. Your height, sex, age and weight have been factored in to your score to better predict your risk for knee osteoarthritis.</p>

                        </div>
                    </>
                ) : (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for knee osteoarthritis.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-Mars_KOsteo-histogram' />
                            <br></br>
                            <p>You are in the <b>{getPercentileText2(percentile)}</b> and your risk for knee osteoarthritis is <b>{disease_risk.toPrecision(2)}</b> fold compared to the average. </p>
                        </div>
                    </>
                )}
                <div>
                </div>
                <div className='title-break'>
                <span>Comments</span>
            </div>
            <div className="card">
                <p>This study developed a polygenic risk score for knee osteoarthritis that includes 1,052,275 variants based on 306,418 individuals (24,955 cases).</p>
                <p>The study was limited to individuals of European ancestry. It is not clear how well the PRS score performs for other ancestries.</p>
            </div>                
            </div>

        return (
            <div>
            <div className='title-margins'>
                <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Knee Osteoarthritis</h2>
                <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a>]</h5>
            </div>

                {testComponent}

            </div>
        );
    };
};


export default Mars_KOsteoResults;
