import React from "react";
import BetaHistogram from "../../components/beta-histogram";
import TagPercentile from "../../components/tag-percentile";
import RiskFactorGraph from "../../components/risk-factor-graph";
import { getPercentileText2 } from "../../utils/results";

export const Mars_AtDer_Results = (props) => {
  const { crm, result, userName, nameEndsWithS } = props;
  let testComponent;
  let percentile;
  let adjusted = result["adjusted_result"];
  if (result.hasOwnProperty("percentile")) {
    percentile = result["percentile"];
  }
  if (result.hasOwnProperty("adjusted_result")) {
    adjusted = result["adjusted_result"];
  }
  console.log("result :", result);
  console.log("percentile :", percentile);
  console.log("adjusted :", adjusted);
  if (result["value"] !== null) {
    testComponent = (
      <div>
        <div className="title-break">
          <span>Your Results</span>
        </div>
        <div className="card">
          <p>
            To use the graph, find the red line that shows your genetic score.
            The blue histogram shows the number of people and their genetic
            scores. The bin containing your score tells how many other people
            have a score like yours. If your score is on the{" "}
            <strong>right side of the histogram</strong>, you will tend to have
            higher risk for atopic dermatitis. (Units for Beta are arbitrary.)
          </p>
          <div>
            {adjusted ? (
              <>
                <BetaHistogram
                  crm={crm}
                  result={result}
                  crmKey="results-adjusted_Mars_AtDer-histogram"
                />
                <p>
                  {" "}
                  You are in the <b>{getPercentileText2(percentile)} </b>. Your
                  age, sex, height and weight have been factored in to your
                  score to better predict your risk for BMI.
                </p>
              </>
            ) : (
              <>
                <BetaHistogram
                  crm={crm}
                  result={result}
                  crmKey="results-Mars_AtDer-histogram"
                />
                <TagPercentile result={props.result} tag="Mars_AtDer" />
              </>
            )}
          </div>
        </div>
        <div className="title-break">
          <span>How To Interpret Your Results</span>
        </div>
        
        <div className="title-break">
          <span>Comments</span>
        </div>
        <div className="card">
          <p>
            This study developed a polygenic risk score for atopic dermatitis
            that includes 1,090,703 total variants based on 306,418 individuals.
          </p>
          <p>OR per SD = 1.28 (1.21–1.34) for atopic dermatitis.</p>
          <p>
            The study was limited to individuals of European ancestry. It is not
            clear how well the genetics perform for other ancestries.
          </p>
        </div>

        {adjusted
          ? (() => {
              const graphMax = 0.07;
              const data = [0.01324, 0.05262, 0.06523];
              return (
                <div>
                  <div className="title-break">
                    <span>
                      Impact of Genetics and Clinical Risk Factors on Risk for
                      AFIB
                    </span>
                  </div>
                  <div className="card">
                    <RiskFactorGraph graphMax={graphMax} data={data} />
                    <p>
                      The graph shows how risk for AFIB is affected by genetics,
                      risk factors (height, age, weight, sex) or both combined.
                      The amount of risk is measured by "variance explained",
                      where 1.00 means that the test predicts AFIB exactly and
                      0.00 means that there is no information. For AFIB,
                      genetics has a smaller effect than risk factors, but
                      combining them together has the most effect; overall, the
                      combined score explains 6.5% of the variance in the AFIB.{" "}
                    </p>
                  </div>
                </div>
              );
            })()
          : null}
      </div>
    );

    return (
      <div>
        <div className="title-margins">
          <h2>
            {userName ? `${userName}'${nameEndsWithS ? "" : "s"}` : ""} Results
            for Atopic Dermatitis          </h2>

          <h5>
            <a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">
              Mars et al., 2021
            </a>
          </h5>
        </div>
        {testComponent}
      </div>
    );
  }
};

export default Mars_AtDer_Results;
