import React from 'react';
import FAQ from '../../components/faq';


export class OsteoFAQ extends React.Component {
  render() {
    return (
      <div>
        <div className='title-margins'>
          <h2>Osteoporosis / BMD FAQ</h2>
        </div>
        
        <div className="card">
          <FAQ
            header="How informative is the genetic test?"
            body={
              <span>
                <p>The Bone Mineral Density Genetic Test (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>)</p>
                <ul>
                <li>The Pearson's correlation with bone mineral density is 50%. Pearson's correlation is a statistical term that measures the strength of the linear relationship between the genetic score and measured BMD, where 100% means that they are the same and 0% means that there is no relationship. For genetic tests of complex traits such as BMD, a value of 50% is high and means that there is good agreement between the genetic test and BMD.</li>
                <li>The area-under-the-curve (AUC) for predicting osteoporosis is 0.79. This means that the genetic test can correctly classify people as either having low BMD (i.e. osteoporosis) or unaffected 79% of the time. For comparison, smoking has an AUC of 0.80 for clalssifying lung cancer.</li>
                <li>A low BMD genetic score (&lt;10<sup>th</sup> percentile) indicates a 17-fold increased risk for osteoporosis. </li>
                </ul>
                {/* <p>The Osteoporosis Genetic Test (<a href="https://pubmed.ncbi.nlm.nih.gov/35324888/">Tanigawa et al., 2022</a>)</p> */}
                {/* <ul>
                <li>Nagelkerke's correlation for osteoporosis = 0.009. </li>
                <li>The area-under-the-curve (AUC) for predicting osteoporosis is 0.574.</li>
                <li>A low osteoporosis genetic score (&lt;10<sup>th</sup> percentile) indicates a two-fold increased risk.</li>
                </ul> */}
                </span>
            }
          />
          {/* <FAQ
            header="Can I combine the information from the two genetic tests?"
            body={
              <span>
                <p>Currently, it is not clear how to combine the results from the bone mineral density and osteoporosis genetic tests. The two tests are partially correlated (28%), which means that the two scores will often agree but some people will get a high score for one test but a low score for the other. The BMD test seems to be more informative than the osteoporosis test, but there is not enough data yet to know how to combine the two tests. </p>
              </span>
            }
          /> */}
            <FAQ
            header="What if I have a high genetic score?"
            body={
              <span>Good News! Individuals with high scores correlate with high bone mineral density and a lower risk for osteoporosis.</span> 
            }
          />
          <FAQ
            header="Is the BMD genetic test useful if BMD is directly measured with dual X ray absorbtiometry (DXA)?"
            body={
              <span><p>The BMD genetic score is partially redundant with DXA measurement for predicting osteoporosis. Your genetic score never changes and indicates your inherent bone mineral density. A DXA bone mineral density scan will change as you grow older.</p>
              <p>The osteoporosis genetic score is largely independent of bone mineral density, so it is informative whether or not you have DXA results.</p>
              </span>
            }
          />
          <FAQ
            header="Does a person's age and sex change the projected risk for osteoporosis?"
            body={
              <span>Your genetics do not change, so your risk for osteoporosis due to genetics will not change. However, your age and sex affect your risk for osteoporosis, so your absolute risk for osteoporosis increases as you get older and if you are female (especially post-menopausal). T scores drop by an average of 0.18 over 10 years, so any person could expect a T score in 20 years that is 0.36 units below the current value. Women get osteoporosis four times more than men.</span>
            }
          />
          <FAQ
            header="How well do the genetic tests work for individuals with African, Asian or Indian ancestry?"
            body={
              <span><p>For non-Europeans, the bone mineral density genetic test still predicts osteoporosis but not as well as in Europeans (<a href="https://www.ncbi.nlm.nih.gov/pubmed/30048462">Kim, 2018</a>). </p>
              <ul>
                <li>For Europeans, the BMD algorithm shows 50% correlation with BMD.</li>
                <li>For Asians, the BMD algorithm shows 28% correlation (60% compared to Eur.).</li>
                <li>For Africans, the BMD algorithm shows 24% correlation (47% compared to Eur.).</li>
                <li>For Indians, the BMD algorithm shows 34% correlation (70% compared to Eur.).</li>
              </ul>
              <p>The osteoporosis genetic test is not informative for non-European ancestry groups (Asian, African, Indian)(<a href="https://pubmed.ncbi.nlm.nih.gov/35324888/">Tanigawa et al., 2022</a>).</p>
              <p>The lower performance in non-Europeans is expected as both genetic tests were developed using European subjects.</p>
              </span>
            }
          />
          <FAQ
            header="Are there genes and pathways that help explain the underlying basis for bone mineral density?"
            body={
              <span>Bioinformatic and genetic studies identified GPC6 as a potential causal gene for low BMD (<a href="https://www.ncbi.nlm.nih.gov/pubmed/28869591">Kemp et al., 2017</a>). GPC6 encodes a glypican, which is a cell-surface signaling protein that functions to reduce bone mineral content.</span>
            }
          />
        </div>
      </div>
    )
  }
}

export default OsteoFAQ;
