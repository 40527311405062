import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2} from '../../utils/results';

export const Clark_myoResults = (props) => {
    const { crm, result, userName, nameEndsWithS } = props;
    let testComponent;
    let percentile; 
    // let adjusted;
    let adjusted = result['adjusted_result'];
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
    }
    // const { adjusted_result } = result;
    if (result['value'] !== null) {
        // let prefixedGeneticScore = result['value'] > 0 ? `+${result['value'].toFixed(2)}` : `${result['value'].toFixed(2)}`;
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have higher risk for myopia. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_Clark_myo-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>. Your age and sex have been factored in to your score to better predict your risk for abdominal aortic aneurysm.</p>

                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-Clark_myo-histogram' />
                                    <TagPercentile result={props.result} tag='Clark_myo' />

                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                <img src="/assets/imgs/results/content/Clark_myo_chart.png" height={1100} className="overview-img" alt="" />
                <p>Figure 3 from Clark et al. (a) Distribution of refractive error by PGS decile. The white box corresponds to the interquartile range, the solid line inside the white box is the median. (b, c) Absolute risk of high myopia of at least −5.00 D (HM5; b) or of at least −6.00 D (HM6; c). Points correspond to the proportion of individuals in each decile affected by high myopia; error bars are 95% confidence intervals. Counts of affected individuals are shown above each point. The dashed horizontal line is the prevalence of high myopia in the full sample.</p>
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                <p>The SNP heritability of refractive error has been estimated at 35–45%.</p>

                <p>This study developed a polygenic risk score for abdominal aortic aneurysm that includes 767,867 variants based on &gt;114,613 individuals.</p>
                <p>For high myopia of −5.00 D or worse (HM5), the AUROC for the new PGS was 0.77 (95% CI 0.75–0.80) whilst for high myopia of −6.00 D or worse (HM6), the AUROC was 0.78 (95% CI 0.75–0.81).</p>
                <p>Individuals in the lowest 5% of the PGS distribution had a 6.2-fold increased risk of HM6 compared to the remainder of the population. Individuals in the lowest 10% of the PGS distribution had a 5.6-fold increased risk of HM3 compared to the remainder of the population. </p>
                <p>The PGS for refractive error explains 19% of the variance in refractive error in Europeans (about 50% of the heritability), 2% in African ancestry individuals, 6% in East Asian and 8% in South Asian ancestry individuals</p>
                <p>Stuart's eyesight is -4.5 D (right) and -6.00 D (left) as of 2024.</p>


                </div>

                {
  adjusted ? (
    (() => {
      const graphMax = .09 ;
      const data = [0.0071,  0.074, 0.082];
      return (
        <div>
          <div className='title-break'>
            <span>Impact of Genetics and Clinical Risk Factors on Risk for Abdominal Aortic Aneurysm</span>
          </div>
          <div className="card">
            <RiskFactorGraph graphMax={graphMax} data={data} />
            <p>The graph shows how risk for abdominal aortic aneurysm is affected by genetics, risk factors (height, age, weight, sex) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts abdominal aortic aneurysm exactly and 0.00 means that there is no information. For abdominal aortic aneurysm, genetics has a smaller effect than risk factors, but combining them together has the most effect; overall, the combined score explains 8.2% of the variance in the abdominal aortic aneurysm. </p>
          </div>
        </div>
      );
    })()
  ) : null
}
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Myopia</h2>
                    <h5>[<a href="https://pubmed.ncbi.nlm.nih.gov/37055258/">Clark et al., 2023</a>]</h5>

                </div>
                {testComponent}

            </div>
            
        );
    };
};


export default Clark_myoResults;
