import React from "react";
import BetaHistogram from "../../components/beta-histogram";
import TagPercentile from "../../components/tag-percentile";
import RiskFactorGraph from "../../components/risk-factor-graph";
import { getPercentileText2 } from "../../utils/results";
import { useSelector } from "react-redux";

export const Xu_cbc_mch = ({ uploadID, userName, nameEndsWithS }) => {
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));
  
  const tag = "Xu_mch";
  const tagResult = ((uploads[uploadID] || {}).results || {})[tag] || {};
  const percentile = tagResult.percentile;
  const adjusted = tagResult.adjusted_result;
  const scoreValue = tagResult.value;
  // If no score value, do not render the component
  if (scoreValue === null) return null;

  return (
    <div>
      <div className="title-margins">
        <h2>
          {userName ? `${userName}'${nameEndsWithS ? "" : "s"}` : ""} Results
          for Mean Corpuscular Hemoglobin (MCH){" "}
        </h2>
        <h5>
          <a href="https://pubmed.ncbi.nlm.nih.gov/35072137/">
            Xu et al., 2022
          </a>
        </h5>
      </div>

      <div>
        <div className="title-break">
          <span>Your Results</span>
        </div>
        <div className="card">
          <p>
            To use the graph, find the red line that shows your genetic score.
            The blue histogram shows the number of people and their genetic
            scores. The bin containing your score tells how many other people
            have a score like yours. If your score is on the{" "}
            <strong>right side of the histogram</strong>, you will tend to have
            a higher mean corpuscular hemoglobin reading.
          </p>
          <div>
            {adjusted ? (
              <>
                <BetaHistogram
                  crm={crm}
                  result={tagResult}
                  crmKey="results-adjusted_Xu_cbc_mch-histogram"
                />
                <p>
                  Your adjusted PRS predicts your MCH count to be{" "}
                  <b>{Number(adjusted).toPrecision(4)} pg</b>. You are in the{" "}
                  <b>{getPercentileText2(percentile)} </b>. Your height, weight,
                  age, sex, smoking status, and alcohol consumption have been
                  factored into your score to better predict your MCH.
                </p>
              </>
            ) : (
              <>
                <BetaHistogram
                  crm={crm}
                  result={tagResult}
                  crmKey="results-Xu_cbc_mch-histogram"
                />
                <TagPercentile result={tagResult} tag="Xu_mch" />
              </>
            )}
          </div>
        </div>

        <div className="title-break">
          <span>Comments</span>
        </div>
        <div className="card">
          <p>
            This study developed a polygenic risk score for MCH that includes
            27,081 total variants (PGS000099) using elastic net.
          </p>
          <p>R2 = 0.2187 for the platelet count PRS with no risk factors.</p>
          <p>R2 = 0.2805 for adjusted risk PRS.</p>
          <p>
            Normal range: 27.0 - 33.0 pg. Stuart has a measured value of 31.8
            pg.
          </p>
        </div>

        {adjusted && (
          <div>
            <div className="title-break">
              <span>Impact of Genetics and Clinical Risk Factors on MCH</span>
            </div>
            <div className="card">
              <RiskFactorGraph graphMax={0.3} data={[0.2187, 0.06237, 0.2805]} />
              <p>
                The graph shows how MCH level is affected by genetics, risk
                factors (height, weight, age, sex, smoking, alcohol), or both
                combined. The amount of risk is measured by "variance
                explained", where 1.00 means that the test predicts MCH exactly
                and 0.00 means that there is no information. For MCH, genetics
                has a much larger effect than risk factors, but combining them
                together has the most effect; overall, the combined score
                explains 28% of the variance.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Xu_cbc_mch;



// import React from "react";
// import BetaHistogram from "../../components/beta-histogram";
// import TagPercentile from "../../components/tag-percentile";
// import RiskFactorGraph from "../../components/risk-factor-graph";
// import { getPercentileText2 } from "../../utils/results";
// import { useSelector } from "react-redux";

// export const Xu_cbc_mch = (props) => {
//   const { uploadID, userName, nameEndsWithS } = props;
//   const { crm, uploads } = useSelector((state) => ({
//     crm: state.crm,
//     uploads: state.results.uploads,
//   }));
//   let tag = "Xu_mch";
//   let testComponent;
//   let percentile;
//   let adjusted;
//   const tagResult = ((uploads[uploadID] || {})["results"] || {})[tag] || {};

//   if (tagResult.hasOwnProperty("percentile")) {
//     percentile = tagResult["percentile"];
//   }
//   if (tagResult.hasOwnProperty("adjusted_result")) {
//     adjusted = tagResult["adjusted_result"];
//   }
//   if (tagResult["value"] !== null) {
//     testComponent = (
//       <div>
//         <div className="title-break">
//           <span>Your Results</span>
//         </div>
//         <div className="card">
//           <p>
//             To use the graph, find the red line that shows your genetic score.
//             The blue histogram shows the number of people and their genetic
//             scores. The bin containing your score tells how many other people
//             have a score like yours. If your score is on the{" "}
//             <strong>right side of the histogram</strong>, you will tend to have
//             a higher mean corpuscuar hemoglobin reading.
//           </p>
//           <div>
//             {adjusted ? (
//               <>
//                 <BetaHistogram
//                   crm={crm}
//                   result={tagResult}
//                   crmKey="results-adjusted_Xu_cbc_mch-histogram"
//                 />
//                 <p>
//                   {" "}
//                   Your adjusted PRS predicts your MCH count to be{" "}
//                   <b>{Number(tagResult.adjusted_result).toPrecision(4)} pg</b>.
//                   You are in the <b>{getPercentileText2(percentile)} </b>. Your
//                   height, weight, age, sex, smoking status and alcohol
//                   consumption have been factored in to your score to better
//                   predict your MCH.
//                 </p>
//               </>
//             ) : (
//               <>
//                 <BetaHistogram
//                   crm={crm}
//                   result={tagResult}
//                   crmKey="results-Xu_cbc_mch-histogram"
//                 />
//                 <TagPercentile result={tagResult["value"]} tag="Xu_mch" />
//               </>
//             )}
//           </div>
//         </div>

//         <div className="title-break">
//           <span>Comments</span>
//         </div>
//         <div className="card">
//           <p>
//             This study developed a polygenic risk score for MCH that includes
//             27,081 total variants (PGS000099) using elastic net.
//           </p>
//           <p>R2 = 0.2187 for the platelet count PRS with no risk factors. </p>
//           <p>R2 = 0.2805 for adjusted risk PRS .</p>
//           <p>
//             Normal range: 27.0 - 33.0 pg. Stuart has a measured value of 31.8
//             pg.
//           </p>
//         </div>

//         {adjusted
//           ? (() => {
//               const graphMax = 0.3;
//               const data = [0.2187, 0.06237, 0.2805];
//               return (
//                 <div>
//                   <div className="title-break">
//                     <span>
//                       Impact of Genetics and Clinical Risk Factors on MCH
//                     </span>
//                   </div>
//                   <div className="card">
//                     <RiskFactorGraph graphMax={graphMax} data={data} />
//                     <p>
//                       The graph shows how MCH level is affected by genetics,
//                       risk factors (height, weight, age, sex, smoking, alcohol)
//                       or both combined. The amount of risk is measured by
//                       "variance explained", where 1.00 means that the test
//                       predicts MCH exactly and 0.00 means that there is no
//                       information. For MCH, genetics has a much larger effect
//                       than risk factors, but combining them together has the
//                       most effect; overall, the combined score explains 28% of
//                       the variance.{" "}
//                     </p>
//                   </div>
//                 </div>
//               );
//             })()
//           : null}
//       </div>
//     );

//     return (
//       <div>
//         <div className="title-margins">
//           <h2>
//             {userName ? `${userName}'${nameEndsWithS ? "" : "s"}` : ""} Results
//             for Mean Corpuscular Hemoglobin (MCH){" "}
//           </h2>

//           <h5>
//             <a href="https://pubmed.ncbi.nlm.nih.gov/35072137/">
//               Xu et al., 2022
//             </a>
//           </h5>
//         </div>
//         {testComponent}
//       </div>
//     );
//   }
// };

// export default Xu_cbc_mch;
