import React from "react";
import BetaHistogram from "../../components/beta-histogram";
import TagPercentile from "../../components/tag-percentile";
import RiskFactorGraph from "../../components/risk-factor-graph";
import { getPercentileText2 } from "../../utils/results";
import { useSelector } from "react-redux";

export const Xu_cbc_wbc = ({ uploadID, userName, nameEndsWithS }) => {
  const { crm, uploads } = useSelector((state) => ({
    crm: state.crm,
    uploads: state.results.uploads,
  }));

  const tag = "Xu_wbc";
  const tagResult = ((uploads[uploadID] || {}).results || {})[tag] || {};
  const percentile = tagResult.percentile;
  const adjusted = tagResult.adjusted_result;
  const scoreValue = tagResult.value;
  // If no score value, do not render the component
  if (scoreValue === null) return null;

  return (
    <div>
      <div className="title-margins">
        <h2>
          {userName ? `${userName}'${nameEndsWithS ? "" : "s"}` : ""} Results
          for White Blood Cell (WBC) Count{" "}
        </h2>
        <h5>
          <a href="https://pubmed.ncbi.nlm.nih.gov/35072137/">
            Xu et al., 2022
          </a>
        </h5>
      </div>

      <div>
        <div className="title-break">
          <span>Your Results</span>
        </div>
        <div className="card">
          <p>
            To use the graph, find the red line that shows your genetic score.
            The blue histogram shows the number of people and their genetic
            scores. The bin containing your score tells how many other people
            have a score like yours. If your score is on the{" "}
            <strong>right side of the histogram</strong>, you will tend to have
            a higher white blood cell count.
          </p>
          <div>
            {adjusted ? (
              <>
                <BetaHistogram
                  crm={crm}
                  result={tagResult}
                  crmKey="results-adjusted_Xu_cbc_wbc-histogram"
                />
                <p>
                  Your adjusted PRS predicts your WBC count to be{" "}
                  <b>{Number(adjusted).toPrecision(4)} K/uL</b>. You are in the{" "}
                  <b>{getPercentileText2(percentile)} </b>. Your height, weight,
                  age, sex, smoking status, and alcohol consumption have been
                  factored into your score to better predict your WBC count.
                </p>
              </>
            ) : (
              <>
                <BetaHistogram
                  crm={crm}
                  result={tagResult}
                  crmKey="results-Xu_cbc_wbc-histogram"
                />
                <TagPercentile result={tagResult} tag="Xu_wbc" />
              </>
            )}
          </div>
        </div>

        <div className="title-break">
          <span>Comments</span>
        </div>
        <div className="card">
        <p>
            This study developed a polygenic risk score for WBC count that
            includes 28,383 total variants (PGS000113) using elastic net.
          </p>
          <p>R2 = 0.142 for the platelet count PRS with no risk factors.      </p>
          <p>R2 = 0.206 for adjusted risk PRS .</p>
          <p>Normal range: 4.0 - 11.0 K/uL.  Stuart has a measured value of 6.4 K/uL.</p>
        </div>

        {adjusted && (
          <div>
            <div className="title-break">
              <span>
                Impact of Genetics and Clinical Risk Factors on WBC count
              </span>
            </div>
            <div className="card">
              <RiskFactorGraph
                graphMax={0.22}
                data={[0.142, 0.063, 0.206]}
              />
              <p>
                The graph shows how WBC count is affected by genetics, risk
                factors (height, weight, age, sex, smoking, alcohol), or both
                combined. The amount of risk is measured by "variance
                explained", where 1.00 means that the test predicts WBC count
                exactly and 0.00 means that there is no information. For WBC
                count, genetics has a much larger effect than risk factors, but
                combining them together has the most effect; overall, the
                combined score explains 20% of the variance.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Xu_cbc_wbc;
