import React from 'react';
import BetaHistogram from '../../components/beta-histogram';
import TagPercentile from '../../components/tag-percentile';
import RiskFactorGraph from '../../components/risk-factor-graph';
import { getPercentileText2} from '../../utils/results';

export const BMIResults = (props) => {
    const { crm, result, userName, nameEndsWithS } = props;
    let testComponent;
    let percentile;
    let adjusted = result['adjusted_result'];
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
       adjusted = result['adjusted_result'];
       
    }
    console.log('result :', result)
    console.log('percentile :', percentile)
    console.log('adjusted :', adjusted)
    if (result['value'] !== null) {
        testComponent =
            <div>
                <div className='title-break'>
                    <span>Your Results</span>
                </div>
                <div className="card">
                    <p>To use the graph, find the red line that shows your genetic score. The blue histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>left side of the histogram</strong>, you will tend to have lower BMI. (Units for Beta are arbitrary.)</p>
                    <div>
                        {
                            adjusted ? (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-adjusted_bmi-histogram' />
                                    <p> You are in the <b>{getPercentileText2(percentile)} </b>. Your age, sex, height and weight have been factored in to your score to better predict your risk for BMI.</p>
                                </>
                            ) : (
                                <>
                                    <BetaHistogram crm={crm} result={result} crmKey='results-bmi-histogram' />
                                    <TagPercentile result={props.result} tag='bmi' />
                                </>
                            )
                        }

                    </div>

                </div>
                <div className='title-break'>
                    <span>How To Interpret Your Results</span>
                </div>
                <div className="card">
                <img alt="" className="overview-img" src="/assets/imgs/results/content/BMI.jpg" height={400}/> 
                    <p>Graph shows estimated BMI for each decile. For someone in the last decile, average BMI is 28.8, compared to 26.6.</p>
                </div>

                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                    <p>BMI PRS R2 = 13.1% using ldpred. Number of Variants = 2,100,302. </p>
                    <p>BMI is a highly heritable complex trait, with heritability estimated to be between 30U+002d 40%</p>
                    <p>The largest meta-analysis of GWAS so far, including ~700,000 adults, identified 941 genetic variants associated with BMI.</p>
                    <p>The GRS constructed using GWS SNPs (941 SNPs) explained only 6.0% of BMI variance,</p>
                    <p>The difference in the median BMI between the top and the bottom deciles of the individuals, according to their PRS, was 5.17 kg/m2</p>
                    <p>No evidence was observed for associations between BMI and any of the three genetic principal components.</p>
                    <p>Stuart bmi = 26.6</p>
                    <p>smoking, alcohol, poor diet, more education, low mental health, good sleep &rarr; less BMI.</p>
                    <p>The FTO locus is the primary locus known to interact with physical activity. In particular, the minor allele of the FTO lead SNP rs9939609 is associated with increased BMI. </p>
                </div>

                {
                    adjusted ? (
                        (() => {
                            const graphMax = .07;
                            const data = [0.01324, 0.05262, .06523];
                            return (
                                <div>
                                    <div className='title-break'>
                                        <span>Impact of Genetics and Clinical Risk Factors on Risk for AFIB</span>
                                    </div>
                                    <div className="card">
                                        <RiskFactorGraph graphMax={graphMax} data={data} />
                                        <p>The graph shows how risk for AFIB is affected by genetics, risk factors (height, age, weight, sex) or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts AFIB exactly and 0.00 means that there is no information. For AFIB, genetics has a smaller effect than risk factors, but combining them together has the most effect; overall, the combined score explains 6.5% of the variance in the AFIB. </p>
                                    </div>
                                </div>
                            );
                        })()
                    ) : null
                }
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Body Mass Index</h2>

                    <h5><a href="https://pubmed.ncbi.nlm.nih.gov/34662357/">Borisevitch et al., 2021</a></h5>
                </div>
                {testComponent}

            </div>

        );
    };
};


export default BMIResults;
