import React from 'react';
import RiskFactorGraph from '../../components/risk-factor-graph';
import RiskGraph from '../../components/risk-line-graph';
import { getPercentileText2, findBucket } from '../../utils/results';

// UER.percentile is required or the page does not load.


const ChartConfig = require('../../../json/ostp-config.json');

export const Mars_arthResults = (props) => {
    const { result, userName, nameEndsWithS } = props;
    let testComponent, ArthIndex;
    const setupData = ChartConfig.setupData;
    const { Mars_arth_Risk,  adjusted_Mars_arth_Risk,  avg_Mars_arth_Result,  avg_Adjusted_Mars_arth_Result, percentile_to_SD } = setupData;
    let adjusted = result['adjusted_result'];
    let percentile, raw_result, SD, OR, disease_risk;
    let ArthRiskPercent, ArthRiskPercent2;
    if (result.hasOwnProperty('percentile')) {
        percentile = result['percentile']
    }
    if (result.hasOwnProperty('adjusted_result')) {
        ArthIndex = findBucket(adjusted, avg_Adjusted_Mars_arth_Result); 
        adjusted = result['adjusted_result'];
        ArthRiskPercent2 = adjusted_Mars_arth_Risk[ArthIndex];  
    } else {
        raw_result = result['value'];
        ArthIndex = findBucket(raw_result, avg_Mars_arth_Result);   
        ArthRiskPercent = Mars_arth_Risk[ArthIndex];  
    }
    SD = percentile_to_SD[percentile-1]
    OR = 1.72  //fill in manually for each disease
    disease_risk = Math.pow(OR, SD)
    
    console.log(`percentile ${percentile}, SD = ${SD}, risk = ${disease_risk}`);
    console.log('ArthIndex :', ArthIndex)
    console.log('ArthRiskPercent :', ArthRiskPercent)
    console.log('raw_result :', raw_result)

    if (result['value'] !== null) {

        testComponent =
            <div>
                {adjusted ? (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for chronic kidney disease. Your score is comprised of both the genetic and clinical risk factor results.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-adjusted-Mars_arth-histogram' />
                            <br></br>
                            <p> You are in the <b>{getPercentileText2(percentile)} </b> and your risk for rheumatoid arthritis is <b>{disease_risk.toPrecision(2)}</b> fold compared to the average. Your height, sex, age and weight have been factored in to your score to better predict your risk for rheumatoid arthritis .</p>

                        </div>
                    </>
                ) : (
                    <>
                        <div className='title-break'>
                            <span>Your Results</span>
                        </div>
                        <div className="card">
                            <p>To use the graph, find the dot on the line that shows your genetic score. The grey histogram shows the number of people and their genetic scores. The bin containing your score tells how many other people have a score like yours. If your score is on the <strong>right side of the histogram</strong>, you will tend to have a higher risk for rheumatoid arthritis.</p>
                            <RiskGraph graphType="risk" percentile={percentile} crmKey='results-Mars_arth-histogram' />
                            <br></br>
                            <p>You are in the <b>{getPercentileText2(percentile)}</b> and your risk for rheumatoid arthritis is <b>{disease_risk.toPrecision(2)}</b> fold compared to the average. </p>
                        </div>
                    </>
                )}
                <div>
                </div>
                <div className='title-break'>
                    <span>Comments</span>
                </div>
                <div className="card">
                    <p>This study developed a polygenic risk score for rheumatoid arthritis that includes 1,083,565 variants based on 306,418 individuals (14,361 cases).</p>
                    {
                    adjusted ? (
                        <p>The polygenic risk score explains 0.87% of the variance in arthritis risk.</p>
                    ) : (
                        <p>The polygenic risk score explains 0.3% of the variance in arthritis risk.</p>
                        )
                    }
                    <p>The study was performed on individuals of White British descent. It is unclear how these results generalize to other ancestry groups.</p>
                </div>

                {
                    adjusted ? (
                        (() => {
                            const graphMax = .009;
                            const data = [0.002982, 0.005801, 0.008748];
                            return (
                                <div>
                                    <div className='title-break'>
                                        <span>Impact of Genetics and Age on Risk for Rheumatoid Arthritis</span>
                                    </div>
                                    <div className="card">
                                        <RiskFactorGraph graphMax={graphMax} data={data} />
                                        <p>The graph shows how risk for rheumatoid arthritis is affected by genetics, sex or both combined. The amount of risk is measured by "variance explained", where 1.00 means that the test predicts rheumatoid arthritis exactly and 0.00 means that there is no information. For rheumatoid arthritis, genetics has a smaller effect than sex, but combining them together has the most effect; overall, the combined score explains 0.87% of the variance in risk for rheumatoid arthritis. </p>
                                    </div>
                                </div>
                            );
                        })()
                    ) : null
                }
            </div>

        return (
            <div>
                <div className='title-margins'>
                    <h2>{userName ? `${userName}'${nameEndsWithS ? '' : 's'}` : ''} Results for Rheumatoid Arthritis</h2>
                    <h5><a href="https://pubmed.ncbi.nlm.nih.gov/36347255/">Mars et al. (2022)</a></h5>
                </div>
                {testComponent}

            </div>
        );
    };
};


export default Mars_arthResults;
