import React from "react";
import BetaHistogram from "../../components/beta-histogram";
import TagPercentile from "../../components/tag-percentile";
import RiskFactorGraph from "../../components/risk-factor-graph";
import { getPercentileText2 } from "../../utils/results";

export const TG_EAS_Results = (props) => {
  const { crm, result, userName, nameEndsWithS } = props;
  let testComponent;
  let percentile;
  let adjusted = result["adjusted_result"];
  if (result.hasOwnProperty("percentile")) {
    percentile = result["percentile"];
  }
  if (result.hasOwnProperty("adjusted_result")) {
    adjusted = result["adjusted_result"];
  }
  console.log("result :", result);
  console.log("percentile :", percentile);
  console.log("adjusted :", adjusted);
  if (result["value"] !== null) {
    testComponent = (
      <div>
        <div className="title-break">
          <span>Your Results</span>
        </div>
        <div className="card">
          <p>
            To use the graph, find the red line that shows your genetic score.
            The blue histogram shows the number of people and their genetic
            scores. The bin containing your score tells how many other people
            have a score like yours. If your score is on the{" "}
            <strong>right side of the histogram</strong>, you will tend to have
            higher triglycerides. 
          </p>
          <div>
            {adjusted ? (
              <>
                <BetaHistogram
                  crm={crm}
                  result={result}
                  crmKey="results-adjusted_TG_EAS-histogram"
                />
                <p>
                  {" "}
                  You are in the <b>{getPercentileText2(percentile)} </b>. Your
                  age, sex, height and weight have been factored in to your
                  score to better predict your risk for triglyceride level. (Units for Beta are mmol/L triglycerides.) R2 = 0.2164. Stuart's observed triglycerides have been ~2.26 mmol/L compared to a genetic prediction of 2.69. 
                </p>
              </>
            ) : (
              <>
                <BetaHistogram
                  crm={crm}
                  result={result}
                  crmKey="results-TG_EAS-histogram"
                />
                <TagPercentile result={props.result} tag="TG_EAS" />
              </>
            )}
          </div>
        </div>
        <div className="title-break">
          <span>How To Interpret Your Results</span>
        </div>

        <div className="title-break">
          <span>Comments</span>
        </div>
        <div className="card">
          <p>
            This study developed a polygenic risk score for triglycerides that
            includes 1,155,383 total variants (PGS003811_hmPOS_GRCh37.txt.gz).
          </p>
          <p>
            CT-SLEB is a model-free approach that combines multiple techniques,
            including a two-dimensional (2D) extension of the popular clumping
            and thresholding (CT) method, a superlearning (SL) model for
            combining multiple PRSs and an empirical Bayes (EB) approach for
            effect-size estimation.{" "}
          </p>
          <p>PRS-CSx had the best performance for TG in the EAS population.</p>
          <p>Estimated heritability using LDSC is 0.094 (0.011).</p>
          <p>
            R2 (95% CI) prediction of TG for EUR with PRS-CSx is 0.128 (0.116 -
            0.141). [how can R2 be higher than LDSC heritability?]
          </p>
          <p>
            R2 (95% CI) prediction of TG for EAS with PRS-CSx is 0.103 (0.069 -
            0.144).
          </p>
          <p>
            R2 (95% CI) prediction of TG for SAS with PRS-CSx is 0.121 (0.104 -
            0.138).
          </p>
          <p>
            R2 (95% CI) prediction of TG for AFR with CT-SLEB is 0.041 (0.030 -
            0.053).
          </p>
          <p>PRS-CSx is best for EAS and SAS. CT-SLEB is best for AFR. </p>
          <p>same group published <a href="https://www.nature.com/articles/s41467-024-47357-7?fromPaywallRec=false"> PROSPER</a>, which performs a little better for TG with EAS. </p>
          <p>
            Find ancestry of user &rarr; use PRS for that ancestry &rarr; TG
            prediction.
          </p>
          <p>
            R2 (95% CI) prediction of TG for EAS with adjusted TG_EAS is 0.2164.
          </p>
          <p>
            <i>
              What about users that are not in one ancestry group, such as
              Jesse?
            </i>
          </p>
          <p>
            Triglyceride levels were calculated from ~275K people from UKB. There wasn't enough data to calculate triglyceride levels for EAS.
          </p>
        </div>

        {adjusted
          ? (() => {
              const graphMax = 0.22;
              const data = [0.0582, 0.126, 0.216];
              return (
                <div>
                  <div className="title-break">
                    <span>
                      Impact of Genetics and Clinical Risk Factors on Risk for
                      Triglyceride level
                    </span>
                  </div>
                  <div className="card">
                    <RiskFactorGraph graphMax={graphMax} data={data} />
                    <p>
                      The graph shows how  triglyceride level is affected by genetics,
                      risk factors (height, age, weight, sex) or both combined.
                      The amount of risk is measured by "variance explained",
                      where 1.00 means that the test predicts TG exactly and
                      0.00 means that there is no information. For TG,
                      genetics has a smaller effect than risk factors, but
                      combining them together has the most effect; overall, the
                      combined score explains 21.6% of the variance in the AFIB.{" "}
                    </p>
                  </div>
                </div>
              );
            })()
          : null}
      </div>
    );

    return (
      <div>
        <div className="title-margins">
          <h2>
            {userName ? `${userName}'${nameEndsWithS ? "" : "s"}` : ""} Results
            for Triglycerides (EAS){" "}
          </h2>

          <h5>
            <a href="https://doi.org/10.1038/s41588-023-01501-z">
              Zhang et al., 2023
            </a>
          </h5>
        </div>
        {testComponent}
      </div>
    );
  }
};

export default TG_EAS_Results;
